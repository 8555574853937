import React from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '../utils/bugsnag';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  componentDidCatch(error, errorInfo) {
    Bugsnag.notify(error);
    console.log(error, errorInfo);
  }

  render() {
    const { props: { children } } = this;

    return children;
  }
}

export default ErrorBoundary;
