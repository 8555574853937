/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import errorsDispatcher from '../helpers/errorsDispatcher';
import { Provider } from '../context/AuthContext';

class AuthStore extends Component {
  state = {
    ...this.props,
    phone: this.props.phone || '(0',
    purePhone: this.props.purePhone || '',
    error: this.props.error || '',
    loading: this.props.loading || false,
    attemptsExhausted: this.props.attemptsExhausted || false,
    codeAttemptsExhausted: this.props.codeAttemptsExhausted || false,
    smsCodeIsComplete: this.props.smsCodeIsComplete || false,
    wrongOperator: this.props.wrongOperator || false,
    codeRequestsLimit: false,
    smsCode: '',
    authRef: this.props.authRef || null,
    isRequestSmsCodeSent: false,
    setPhone: this.props.setPhone || (phone => {
      const purePhone = phone.replace(/\D+/g, '');
      const phoneIsIncomplete = purePhone.length < 10;
      this.setState({
        phone,
        purePhone
      });
      if (phoneIsIncomplete) {
        this.setState({
          attemptsExhausted: false,
          codeAttemptsExhausted: false,
          codeRequestsLimit: false
        });
      }
    }),
    setLoading: loading => {
      this.setState({
        loading
      });
    },
    setError: this.props.setError || ((n, text = '') => {
      const { t } = this.props;
      this.setState({
        error: text || errorsDispatcher(n, t)
      });
    }),
    setAttemptsExhausted: state => {
      this.setState({
        attemptsExhausted: state
      });
    },
    setCodeAttemptsExhausted: this.props.setCodeAttemptsExhausted || (state => {
      this.setState({
        codeAttemptsExhausted: state
      });
    }),
    setSmsCodeIsComplete: this.props.setSmsCodeIsComplete || (state => {
      this.setState({
        smsCodeIsComplete: state
      });
    }),
    setWrongOperator: this.props.setWrongOperator || (state => {
      this.setState({
        wrongOperator: state
      });
    }),
    setCodeRequestsLimit: state => {
      this.setState({
        codeRequestsLimit: state
      });
    },
    setSmsCode: this.props.setSmsCode || (smsCode => {
      this.setState({ smsCode });
    }),
    setAuthRef: this.props.setAuthRef || (authRef => {
      this.setState({ authRef });
    }),
    setIsRequestSmsCodeSent: isRequestSmsCodeSent => {
      this.setState({ isRequestSmsCodeSent });
    }
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
    phone: PropTypes.string,
    purePhone: PropTypes.string,
    setPhone: PropTypes.func,
    setError: PropTypes.func,
    setWrongOperator: PropTypes.func,
    wrongOperator: PropTypes.bool,
    authRef: PropTypes.object,
    setSmsCode: PropTypes.func,
    attemptsExhausted: PropTypes.bool,
    codeAttemptsExhausted: PropTypes.bool,
    setSmsCodeIsComplete: PropTypes.func,
    smsCodeIsComplete: PropTypes.bool,
    setCodeAttemptsExhausted: PropTypes.func,
    setAuthRef: PropTypes.func,
    error: PropTypes.string,
    loading: PropTypes.bool
  };

  render() {
    const { state, props } = this;
    const { children } = props;

    return (
      <Provider value={state}>
        {children}
      </Provider>
    );
  }
}
export default withTranslation()(AuthStore);
