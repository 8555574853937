import { createContext } from 'react';

const AuthContext = createContext(null);
const { Provider } = AuthContext;

export {
  Provider
};

export default AuthContext;
