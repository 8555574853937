import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../context/AuthContext';
import returnToHandler from '../helpers/returnToHandler';
import closeParentIframeModal from '../helpers/closeParentIframeModal';

const closeBtnHandler = (predefinedPhone, returnTo) => (
  (predefinedPhone && window.parent !== window)
    ? closeParentIframeModal()
    : returnToHandler(returnTo)
);

const Layout = ({ children }) => {
  const {
    returnTo,
    setAuthRef,
    predefinedPhone
  } = useContext(AuthContext);
  const authRef = useRef(null);

  useEffect(() => {
    setAuthRef(authRef);
  }, [authRef]);

  return (
    <div className="main">
      <div className="auth" ref={authRef}>
        <button
          type="button"
          className="auth__close-btn"
          onClick={() => closeBtnHandler(predefinedPhone, returnTo)}
        >
          close
        </button>
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired
};

export default Layout;
