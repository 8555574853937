import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from '../config';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_AUTH_SPA,
  appVersion: process.env.APP_VERSION,
  logger: null,
  consoleBreadcrumbsEnabled: false,
  enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
  onError(event) {
    const reportApp = event.app;
    const requestUrl = event.request.url;
    const branchName = process.env.VERCEL_GITHUB_BRANCH || 'staging';
    const productionHostsRegexp = new RegExp(`hello.(${config.allowedHosts.join('|')})`, 'g');

    if (requestUrl.match(productionHostsRegexp)) {
      reportApp.releaseStage = 'production';
      reportApp.notifyReleaseStages = 'production';
    } else {
      reportApp.releaseStage = branchName;
      reportApp.notifyReleaseStages = branchName;
    }
  },
  plugins: [new BugsnagPluginReact()]
});

export default Bugsnag;
