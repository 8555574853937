import base64url from 'base64url';
import config from '../config';

const returnToHandler = returnTo => {
  const { returnToDefault: { url }, allowedHosts } = config;
  const returnToUrl = new URL(base64url.decode(returnTo));
  const returnToHost = returnToUrl.host.replace(/^ru./, '').split('-')[0];

  window.location = allowedHosts.indexOf(returnToHost) > -1 ? returnToUrl.href : url;
};

export default returnToHandler;
