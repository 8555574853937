import base64url from 'base64url';
import config from '../config';
import getUrlParameter from './getUrlParameter';

export default {
  name: 'langdomain',

  lookup(options) {
    const { returnToDefault: { encodedUrl } } = config;
    let found;
    if (typeof window !== 'undefined') {
      const returnToUrlEncoded = getUrlParameter('return_to') || encodedUrl;
      const returnToUrl = base64url.decode(returnToUrlEncoded);
      const language = returnToUrl.match(/(?:http[s]\/\/)*(ru\.)/gi);
      if (language instanceof Array) {
        if (typeof options.lookupFromSubdomainIndex === 'number') {
          found = language[options.lookupFromSubdomainIndex]
            .replace('http://', '')
            .replace('https://', '')
            .replace('.', '');
        } else {
          found = language[0]
            .replace('http://', '')
            .replace('https://', '')
            .replace('.', '');
        }
      }
    }
    return found;
  }
};
